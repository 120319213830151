
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useAppState } from "@/hooks/use-app-state";
import { useAuth } from "@/hooks/use-auth";

export default defineComponent({
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { topbarMenuOpen } = useAppState();
    const route = useRoute();
    const { loggedIn } = useAuth();
    const navbarAbsolute = computed(() => route.meta.navbarAbsolute);

    const menuOpen = ref(topbarMenuOpen);
    menuOpen.value = false; // on setup always false

    const openMenu = () => {
      menuOpen.value = true;
    };
    const closeMenu = () => {
      menuOpen.value = false;
    };
    return { navbarAbsolute, menuOpen, openMenu, closeMenu, loggedIn };
  },
});

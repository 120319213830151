<template>
  <section class="topbar" :class="{ offset: !navbarAbsolute }">
    <button class="button-secondary-icon-only" @click="$router.go(-1)" title="Terug">
      <icon icon="arrow-left" />
    </button>
    <button
      class="button-secondary-icon-only"
      v-if="!menuOpen && !hideMenu && loggedIn"
      @click.stop="openMenu"
      title="Menu"
    >
      <icon icon="bars" />
    </button>

    <div
      class="topbar-menu"
      v-if="menuOpen && !hideMenu && loggedIn"
      v-click-outside="closeMenu"
    >
      <button
        class="button-secondary-icon-only topbar-menu-close"
        @click.stop="closeMenu"
        title="Sluiten"
      >
        <icon icon="times" />
      </button>

      <ul class="topbar-menu-list">
        <slot></slot>
      </ul>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useAppState } from "@/hooks/use-app-state";
import { useAuth } from "@/hooks/use-auth";

export default defineComponent({
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { topbarMenuOpen } = useAppState();
    const route = useRoute();
    const { loggedIn } = useAuth();
    const navbarAbsolute = computed(() => route.meta.navbarAbsolute);

    const menuOpen = ref(topbarMenuOpen);
    menuOpen.value = false; // on setup always false

    const openMenu = () => {
      menuOpen.value = true;
    };
    const closeMenu = () => {
      menuOpen.value = false;
    };
    return { navbarAbsolute, menuOpen, openMenu, closeMenu, loggedIn };
  },
});
</script>

<style lang="scss">
$topbar-height: $icon-height;

.topbar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: sticky;
  top: 1rem;
  margin-bottom: #{$nav-height-md + 10px};
  z-index: 500;
  height: 0;

  @include min-md() {
    z-index: 499;
    margin-left: -70px;
    margin-bottom: 0;

    &.offset {
      top: #{$nav-height-md + 10px};
    }
  }

  &-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    width: 80vw;
    max-width: 260px;
    padding-bottom: 20px;
    z-index: 1;

    @include max-md() {
      position: fixed;
      top: 0;
      height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      // animation: toLeft $transition-properties forwards;
    }

    &-list {
      @extend %reset-list;
      margin-top: 10px;
      border: 1px solid var(--white-500);
      background-color: transparent;
      backdrop-filter: blur(10px);

      @include max-md() {
        border-right: 0;
      }
    }

    &-close {
      flex: 0 0 $icon-height;
      align-self: flex-end;

      @include max-md() {
        margin-top: 1rem;
        margin-right: $grid-padding;
      }
    }
  }
}
</style>
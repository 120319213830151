export enum TimeSignature {
    TwoTwo = '2/2',
    TwoFour = '2/4',
    ThreeFour = '3/4',
    FourFour = '4/4',
    TwoEight = '2/8',
    ThreeEight = '3/8',
    SixEight = '6/8',
    TwelveEight = '12/8'
}

export enum SongPartLineTypes {
    CHORD = 'chord',
    TEXT = 'text'
}

export interface SongPartLine {
    type: SongPartLineTypes,
    content: string
}

export interface SongPart {
    name: string,
    bracketName:  string,
    repeat: number,
    repeated: number,
    lines: Array<SongPartLine>,
    inViewport: boolean
}

export interface Song {
    _id: string,
    short_id: string,
    title: string,
    artist: string,
    key: string,
    bpm: number | null,
    timeSignature: TimeSignature,
    input: string,
    parts: Array<SongPart>,
    recentlyAdded: boolean
}
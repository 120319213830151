import XRegExp from 'xregexp';

export const N_KEYS = 12;

export const FLAT_SCALE = ["C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "Bb", "Cb"];
export const SHARP_SCALE = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
export const COMBINED_SCALE = ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "Ab", "A", "Bb", "B"];

// Regex for recognizing chords
const ROOT_PATTERN = '(?<root>[A-G](#|b)?)';
const TRIAD_PATTERN = '(M|maj|major|m|min|minor|dim|sus|dom|aug|\\+|-)';
const ADDED_TONE_PATTERN = '(([/\\.\\+]|add)?\\d+[\\+-]?)';
const SUFFIX_PATTERN = `(?<suffix>\\(?${TRIAD_PATTERN}?${ADDED_TONE_PATTERN}*\\)?)`;
const BASS_PATTERN = '(\\/(?<bass>[A-G](#|b)?))?';
const MINOR_PATTERN = '(<sub>)?(m|min|minor)+(</sub>)?';
export const CHORD_REGEX = XRegExp(`^${ROOT_PATTERN}${SUFFIX_PATTERN}${BASS_PATTERN}$`);
export const MINOR_CHORD_REGEX = XRegExp(`^${ROOT_PATTERN}${MINOR_PATTERN}.*$`);

export const MAJOR_SCALE = [0, 2, 4, 5, 7, 9, 11];
export const MINOR_SCALE = [0, 2, 3, 5, 7, 8, 11];

export const CHORDS_DEF = {
    major: [4, 7],
    minor: [3, 7]
}
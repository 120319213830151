import indexOf from 'lodash/indexOf';
import isEqual from 'lodash/isEqual';
import pull from 'lodash/pull';
import find from 'lodash/find';
import { reactive } from 'vue';
import { N_KEYS, COMBINED_SCALE } from "@/helpers/transpose";
import { Song } from "@/types/song";
import { useSongs } from './use-songs';

interface SongTranspose {
    id: string,
    capo: number,
    key: string
}

export interface TransposeState {
    data: Array<SongTranspose>
}

let transposeState: TransposeState;

export const useTranspose = () => {
    const convertScaleIndex = (index: number): number => {
        if (index < N_KEYS) {
            if (index < 0) {
                return index + N_KEYS;
            } else {
                return index;
            }
        } else {
            return index - N_KEYS;
        }
    };

    const getKeyDiff = (keyOne: string, keyTwo: string): number => {
        return convertScaleIndex(
            indexOf(COMBINED_SCALE, keyOne) -
            indexOf(COMBINED_SCALE, keyTwo)
        );
    }

    const getNewKey = (currentKey: string, addValue: number): string => {
        return COMBINED_SCALE[convertScaleIndex(indexOf(COMBINED_SCALE, currentKey) + addValue)]
    }

    const getNewCapo = (currentCapo: number, addValue: number): number => {
        if (currentCapo + addValue < 0) {
            return 12;
        } else if (currentCapo + addValue > 12) {
            return 0;
        } else {
            return currentCapo + addValue;
        }
    }

    const retrieveSongTransposes = (): Array<SongTranspose> => {
        const stored = localStorage.getItem('song_transposes');
        if (stored) {
            let parsed = JSON.parse(stored) as Array<SongTranspose>;
            return parsed;
        } else {
            return [];
        }
    }

    transposeState = transposeState || reactive<TransposeState>({
        data: retrieveSongTransposes()
    });

    const getTransposeIndex = (id: string): number => {
        return transposeState.data.findIndex(x => x.id == id);
    }

    const getTranspose = (song: Song): SongTranspose => {
        const transposeIndex = getTransposeIndex(song.short_id);
        const transpose = transposeIndex > -1 ? transposeState.data[transposeIndex] : createDefaultTranspose(song);
        return transpose;
    }

    const getDefaultTranspose = (song: Song): SongTranspose => {
        return {
            id: song.short_id,
            key: song.key,
            capo: 0
        };
    }

    const createDefaultTranspose = (song: Song): SongTranspose => {
        const defaultTranspose = getDefaultTranspose(song);
        transposeState.data.push(defaultTranspose);
        return transposeState.data[getTransposeIndex(defaultTranspose.id)];
    }

    const saveTransposes = (): void => {
        // Remove transposes that are the same as the default transpose for that corresponding song
        const transposesCopy: SongTranspose[] = [...transposeState.data];
        const { songs } = useSongs();
        if (songs.value) {
            transposeState.data.forEach(transpose => {
                const matchingSong = find(songs.value, song => song.short_id == transpose.id);
                if(matchingSong && isEqual(transpose, getDefaultTranspose(matchingSong))) {
                    pull(transposesCopy, transpose);
                }
            });
        }

        localStorage.setItem('song_transposes', JSON.stringify(transposesCopy));
    }

    const updateTransposeIfDefault = (oldSong: Song, newSong: Song): void => {
        const transpose = getTranspose(oldSong);

        if (isEqual(transpose, getDefaultTranspose(oldSong))) {
            const index = getTransposeIndex(transpose.id);
            transposeState.data[index] = getDefaultTranspose(newSong);
        }
    }

    return { getTranspose, saveTransposes, updateTransposeIfDefault, convertScaleIndex, getKeyDiff, getNewKey, getNewCapo };
}